// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cv-js": () => import("./../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-cv-pdf-cv-pdf-js": () => import("./../src/pages/cv-pdf/cvPDF.js" /* webpackChunkName: "component---src-pages-cv-pdf-cv-pdf-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-js": () => import("./../src/pages/lab.js" /* webpackChunkName: "component---src-pages-lab-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-writing-js": () => import("./../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-templates-cv-template-js": () => import("./../src/templates/cvTemplate.js" /* webpackChunkName: "component---src-templates-cv-template-js" */),
  "component---src-templates-lab-template-js": () => import("./../src/templates/labTemplate.js" /* webpackChunkName: "component---src-templates-lab-template-js" */),
  "component---src-templates-post-template-js": () => import("./../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tags-template-js": () => import("./../src/templates/tagsTemplate.js" /* webpackChunkName: "component---src-templates-tags-template-js" */)
}

